import { Text, TextProps as ChakraTextProps } from "@chakra-ui/react"

const FONT_SIZES = {
  h1: "40px",
  h2: "32px",
  h3: "24px",
  h4: "16px",
  h5: "12px",
}

const FONT_WEIGHTS = {
  normal: "normal",
  bold: "600",
}

type TextProps = {
  fontSize: string
  fontWeight: string
} & ChakraTextProps

const TextElement = ({ fontSize, fontWeight, children, ...props }: TextProps) => {
  return (
    <Text fontSize={fontSize} fontWeight={fontWeight} color="var(--text)" p="0" m="0" {...props}>
      {children}
    </Text>
  )
}

const createTextComponent = (fontSize: string, fontWeight: string) => {
  const TextComponent = (props: JSX.IntrinsicElements["p"]) => {
    const { children, ...otherProps } = props

    return (
      <TextElement fontSize={fontSize} fontWeight={fontWeight} {...otherProps}>
        {children}
      </TextElement>
    )
  }

  return TextComponent
}

export const H1 = createTextComponent(FONT_SIZES.h1, FONT_WEIGHTS.normal)
export const H2 = createTextComponent(FONT_SIZES.h2, FONT_WEIGHTS.normal)
export const H3 = createTextComponent(FONT_SIZES.h3, FONT_WEIGHTS.normal)
export const H4 = createTextComponent(FONT_SIZES.h4, FONT_WEIGHTS.normal)
export const H5 = createTextComponent(FONT_SIZES.h5, FONT_WEIGHTS.normal)

export const H1Emphasized = createTextComponent(FONT_SIZES.h1, FONT_WEIGHTS.bold)
export const H2Emphasized = createTextComponent(FONT_SIZES.h2, FONT_WEIGHTS.bold)
export const H3Emphasized = createTextComponent(FONT_SIZES.h3, FONT_WEIGHTS.bold)
export const H4Emphasized = createTextComponent(FONT_SIZES.h4, FONT_WEIGHTS.bold)
export const H5Emphasized = createTextComponent(FONT_SIZES.h5, FONT_WEIGHTS.bold)

export default TextElement
