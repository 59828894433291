export const shortenCopyDisplay = (copy: string, maxChar: number = 48): [string, boolean] => {
  if (!copy) {
    return ["", false]
  }

  if (copy.length <= maxChar) {
    return [copy, false]
  }
  return [`${copy.slice(0, maxChar)}...`, true]
}
