import { useEffect } from "react"
import { useState } from "react"

import { Table, Tbody, Td, Tr, Text, Flex } from "@chakra-ui/react"
import Papa from "papaparse"

const MAX_PREVIEW_ROWS = 15

const CsvPreviewer: React.ElementType<{ src: string }> = ({ src }): React.ReactElement => {
  const [data, setData] = useState([])

  useEffect(() => {
    if (!src) {
      return
    }

    try {
      Papa.parse(src, {
        download: true,
        complete: (result) => {
          setData(result.data)
        },
        header: false,
      })
    } catch (error) {
      console.error(error)
    }
  }, [src])

  return (
    <Flex flexDirection="column">
      {data.length > 0 && (
        <Table>
          <Tbody>
            {data.slice(0, MAX_PREVIEW_ROWS).map((row: string[], rowIndex) => (
              <Tr key={rowIndex}>
                {row.map((cell, cellIndex) => (
                  <Td key={cellIndex} fontWeight={rowIndex === 0 ? "bold" : "inherit"}>
                    {cell}
                  </Td>
                ))}
              </Tr>
            ))}
          </Tbody>
        </Table>
      )}

      {data.length > MAX_PREVIEW_ROWS && (
        <Text fontSize="2em" textAlign="center">
          ...
        </Text>
      )}
    </Flex>
  )
}

export default CsvPreviewer
