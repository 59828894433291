import { HStack, Text } from "@chakra-ui/react"
import Link from "next/link"
import { FiSettings } from "react-icons/fi"

import Tooltip from "../Indicators/Tooltip"
import TertiaryButton from "../Buttons/Tertiary"

const AccountNav = ({ name, color = "black" }: { name: string; color?: string }) => {
  return (
    <HStack spacing="8px">
      <Text color={color} fontWeight="500" fontSize="14px">
        {name}
      </Text>
      <Tooltip label="Settings">
        <Link href="/app/settings">
          <TertiaryButton padding={0}>
            <FiSettings color={color} />
          </TertiaryButton>
        </Link>
      </Tooltip>
    </HStack>
  )
}

export default AccountNav
