import * as Sentry from "@sentry/nextjs"

export const humanizeError = (error: any): string => {
  const errorType = typeof error

  if (errorType === "string") return error
  if (errorType === "undefined" || errorType === "function") return ""

  if (Array.isArray(error)) {
    return humanizeError(error.join(", "))
  }

  if (errorType === "object") {
    const nextLayer = error.message || error.error || error.messages || error[Object.keys(error)[0] || ""]

    return humanizeError(nextLayer)
  }
  return `${error}`
}

export const handleClientSideException = (error: any, message: string, showError: (message: string) => void): void => {
  const humanizedError = humanizeError(error)

  Sentry.captureException(error)
  console.error(error)
  console.error(humanizedError)

  showError(`${message}. Please refresh and try again.`)
}

export const handleServerException = (error: any): void => {
  const humanizedError = humanizeError(error)

  Sentry.captureException(error)
  console.error(error)
  console.error(humanizedError)
}
