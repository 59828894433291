import Image from "next/image"

interface LoadingAnimationProps {
  size: string
}

const LoadingAnimation = ({ size }: LoadingAnimationProps) => {
  return <Image src="/images/loading.webp" width={+`${size}`} height={+`${size}`} style={{ margin: "0 auto" }} alt="loading spinner" />
}

export default LoadingAnimation
