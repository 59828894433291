import { useEffect } from "react"
import { useState } from "react"

import { Table, Tbody, Td, Tr, Text, Flex } from "@chakra-ui/react"
import * as XLSX from "xlsx"

const MAX_PREVIEW_ROWS = 10

const ExcelPreviewer: React.ElementType<{ src: string }> = ({ src }): React.ReactElement => {
  const [rows, setRows] = useState<Row[]>([])

  const getFileFromUrl = async (src: string): Promise<Blob> => {
    const blob = await fetch(src).then((r) => r.blob())

    return blob
  }

  type Cell = string | number | undefined
  type Row = Cell[]

  useEffect(() => {
    if (!src) {
      return
    }

    const loadWorkbook = async (): Promise<void> => {
      try {
        // get blob file from src
        const blob = await getFileFromUrl(src)
        const ab = await blob.arrayBuffer()
        const workbook = XLSX.read(ab)

        const first_sheet_name = workbook.SheetNames[0] as string

        /* Get worksheet */
        if (!first_sheet_name) {
          throw new Error("No first sheet name")
        }

        const worksheet = workbook.Sheets[first_sheet_name] as XLSX.WorkSheet
        const rows: Row[] = XLSX.utils.sheet_to_json(worksheet, {
          header: 1,
        })

        setRows(rows)
      } catch (error) {
        console.error(error)
      }
    }

    void loadWorkbook()
  }, [src])

  return (
    <Flex flexDirection="column">
      {rows.length > 0 && (
        <Table>
          <Tbody>
            {rows.slice(0, MAX_PREVIEW_ROWS).map((row: Row, rowIndex) => (
              <Tr key={rowIndex}>
                {row.map((cell: Cell, cellIndex) => (
                  <Td key={cellIndex} fontWeight={rowIndex === 0 ? "bold" : "inherit"}>
                    {String(cell).length > 50 ? `${String(cell).slice(0, 50)}...` : cell}
                  </Td>
                ))}
              </Tr>
            ))}
          </Tbody>
        </Table>
      )}

      {rows.length > MAX_PREVIEW_ROWS && <Text fontSize="2em">...</Text>}
    </Flex>
  )
}

export default ExcelPreviewer
