import useSWR from "swr"
import { getOrganization, QueryParams } from "pages/api/models/organizations/queries/getOrganization"

import { cacheKey, returnedQuery } from "../types"

export default function useGetOrganizationById(params: QueryParams, cacheKey: cacheKey): returnedQuery {
  const { data, error, isLoading } = useSWR([params, cacheKey], ([params]) => {
    if (params.id < 1) {
      return
    }

    return getOrganization(params)
  })

  return {
    organization: data,
    isLoading,
    isError: !!error,
    error,
  }
}
