import { useEffect, useState } from "react"

import mammoth from "mammoth"
import { Card, Alert, AlertIcon, AlertTitle, AlertDescription, Box } from "@chakra-ui/react"

interface WordPreviewModalProps {
  src: string
  blob?: Blob
}

const WordPreviewer = ({ src, blob }: WordPreviewModalProps) => {
  const [htmlContent, setHtmlContent] = useState("")

  useEffect(() => {
    if (blob) {
      const parseDocx = async () => {
        try {
          const arrayBuffer = await blob.arrayBuffer()
          const result = await mammoth.convertToHtml({ arrayBuffer })

          setHtmlContent(result.value)
        } catch (error) {
          setHtmlContent("Unable to load preview")

          console.error(error)
        }
      }

      void parseDocx()
    }
  }, [blob])

  return (
    <Card marginTop="8px">
      <Alert status="warning" borderRadius="12px" marginBottom="20px">
        <AlertIcon />
        <Box>
          <AlertTitle>Previewing Word document</AlertTitle>
          <AlertDescription>Document preview may distort styling or colors.</AlertDescription>
        </Box>
      </Alert>
      <div
        style={{
          padding: ".5in",
          border: "1px solid #ccc",
          boxShadow: "0px 1px 5px #ccc",
        }}
        className="document-preview"
        dangerouslySetInnerHTML={{ __html: htmlContent }}
      />
    </Card>
  )
}

export default WordPreviewer
