import { ReactNode } from "react"

import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, Flex } from "@chakra-ui/react"
import { MdClose } from "react-icons/md"

import TertiaryButton from "../Buttons/Tertiary"
import PrimaryButton from "../Buttons/Primary"
import SecondaryButton from "../Buttons/Secondary"

interface BasicModalProps {
  onClose: () => void
  title?: string
  size?: string
  children: ReactNode
  hideCloseButton?: boolean
  isCentered?: boolean
  showBorder?: boolean
  onModalBodyClick?: () => void
  showActionButtons?: boolean
  showCancelButton?: boolean
  actionText?: string
  onAction?: () => void
  onCancel?: () => void
  isActionLoading?: boolean
  isActionDisabled?: boolean
}

const BasicModal = ({
  onClose,
  title = "",
  children,
  size = "md",
  hideCloseButton = false,
  isCentered = false,
  onModalBodyClick = () => {},
  showBorder = true,
  showActionButtons = false,
  showCancelButton = true,
  actionText = "Action",
  onAction = () => {},
  onCancel,
  isActionLoading = false,
  isActionDisabled = false,
}: BasicModalProps) => {
  return (
    <>
      <Modal isOpen={true} onClose={onClose} size={size} isCentered={isCentered}>
        <ModalOverlay />

        <ModalContent display="flex" flexDirection="column">
          <Flex borderBottom={title && showBorder ? "1px solid var(--card-border)" : "none"} zIndex={9999}>
            {title && <ModalHeader minWidth="100%">{title}</ModalHeader>}

            {!hideCloseButton && (
              <TertiaryButton onClickHandler={onClose} padding="8px" margin="12px" fontSize="1.4rem" position="absolute" right="0px" top="0px">
                <MdClose />
              </TertiaryButton>
            )}
          </Flex>

          <ModalBody flex="1" onClick={onModalBodyClick} pb={showActionButtons ? undefined : "24px"}>
            {children}
          </ModalBody>

          {showActionButtons && (
            <Flex justifyContent="flex-end" p="24px" position="sticky" bottom={0}>
              {showCancelButton && (
                <SecondaryButton autoFocus mr={4} onClickHandler={() => (onCancel ? onCancel() : onClose())}>
                  Cancel
                </SecondaryButton>
              )}
              <PrimaryButton onClickHandler={onAction} isLoading={isActionLoading} isDisabled={isActionDisabled}>
                {actionText}
              </PrimaryButton>
            </Flex>
          )}
        </ModalContent>
      </Modal>
    </>
  )
}

export default BasicModal
