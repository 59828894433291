import { useEffect, useState } from "react"

import { Flex, Code, AlertTitle, Box, AlertIcon, Alert, AlertDescription } from "@chakra-ui/react"

const MAX_PREVIEW_DEPTH = 2
const MAX_PREVIEW_CHARS = 50_000

const JsonPreviewer = ({ src }: { src: string }) => {
  const [data, setData] = useState(null)
  const [error, setError] = useState(null)

  useEffect(() => {
    if (!src) {
      return
    }

    fetch(src)
      .then((response) => response.json())
      .then((json) => setData(json))
      .catch((err) => setError(err.message))
  }, [src])

  const getTruncatedJson = (json) => {
    const jsonString = JSON.stringify(json, null, MAX_PREVIEW_DEPTH)

    return jsonString.length > MAX_PREVIEW_CHARS ? `${jsonString.slice(0, MAX_PREVIEW_CHARS)}...` : jsonString
  }

  return (
    <Flex flexDirection="column" width="100%" overflowX="auto">
      {error && (
        <Box>
          <Alert status="warning" borderRadius="12px" marginBottom="20px">
            <AlertIcon />
            <Box>
              <AlertTitle>Unable to Preview JSON File</AlertTitle>
              <AlertDescription>{error}</AlertDescription>
            </Box>
          </Alert>
        </Box>
      )}
      {data && (
        <Code as="pre" whiteSpace="pre-wrap">
          {getTruncatedJson(data)}
        </Code>
      )}
    </Flex>
  )
}

export default JsonPreviewer
