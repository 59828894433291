import { Flex, Image } from "@chakra-ui/react"
import { File } from "@prisma/client"

import BasicModal from "../MenusAndModals/BasicModal"
import LoadingAnimation from "../Loading/LoadingAnimation"
import CsvPreviewer from "./CsvPreviewer"
import ExcelPreviewer from "./ExcelPreviewer"
import WordPreviewer from "./WordPreviewer"
import JsonPreviewer from "./JsonPreviewer"

const getContentPreviewElement = (blobSource: string, blob?: Blob, fileName?: string) => {
  if (!fileName) {
    return <></>
  }

  const fileType = fileName.split(".").at(-1) || ""

  switch (fileType) {
    case "pdf":
      return <iframe src={blobSource} style={{ width: "100%", height: "40vw", display: "block" }}></iframe>

    case "csv":
      return <CsvPreviewer src={blobSource} />

    case "xlsx":
      return <ExcelPreviewer src={blobSource} />

    case "docx":
      return <WordPreviewer src={blobSource} blob={blob} />

    case "json":
      return <JsonPreviewer src={blobSource} />

    default:
      return <Image src={blobSource} alt="Preview" maxWidth="100%" />
  }
}

const FilePreviewModal = ({ onClose, blobSource, file, blob, documentName }: { onClose(): void; blobSource: string; file: File; blob?: Blob; documentName?: string }) => {
  return (
    <BasicModal title={documentName || file?.name || "File Preview"} onClose={() => onClose()} size="3xl">
      <Flex alignContent="center" maxWidth="100%" overflow="auto">
        {!blobSource ? <LoadingAnimation size="150" /> : getContentPreviewElement(blobSource, blob, file?.name)}
      </Flex>
    </BasicModal>
  )
}

export default FilePreviewModal
